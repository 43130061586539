import * as globalVars from 'common/config';

import { DEPENDENCIES } from './libs';

/////////////////////////////

Object.keys(globalVars).forEach((globalVar) => {
    if (globalVar === 'default') {
        return;
    }

    // eslint-disable-next-line angular/window-service, import/namespace
    window[globalVar] = globalVars[globalVar];
});

// eslint-disable-next-line angular/window-service
window.DEPENDENCIES = DEPENDENCIES;

/////////////////////////////

angular.module('LumSite', []);

angular.module('Constants', []);
angular.module('Controllers', []);
angular.module('CustomServices', []);
angular.module('Directives', ['RecursionHelper']);
angular.module('Factories', ['ngResource']);
angular.module('Filters', []);
angular.module('Services', []);
angular.module('Values', []);
angular.module('Widgets', []);

// eslint-disable-next-line import/exports-last
export const FRONT_OFFICE = angular.module(globalVars.APPLICATION_NAME, DEPENDENCIES);

/**
 * Import FO modules
 */
angular.element(document).ready(() => import(
    /* webpackChunkName: "front-office-components" */
    './ready'
));
