/* eslint-disable import/no-unassigned-import */

// Include all library based dependencies.
import 'angular';
import 'angular-bind-html-compile-ci-dev';
import 'angular-cookies';
import 'angular-drag-and-drop-lists';
import 'angular-emoji-filter-hd';
import 'angular-http-auth';
import 'angular-promise-extras';
import 'angular-recursion';
import 'angular-resource';
import 'angular-sanitize';
import 'angular-translate';
import 'angulartics';
import 'angulartics-google-analytics';
import 'ng-sortable';
import 'ng-timezone';
import 'ngstorage';
import 'oclazyload';
import '@lumx/angularjs';
import 'marked';
import './base_service';
import 'velocity-animate';

// Include all vendorised libraries.
import '../vendors/angular-hotkeys/build/hotkeys';
import '../vendors/angular-ui-router/release/angular-ui-router';
import '../vendors/polyfills/intersection-observer';

/////////////////////////////
//                         //
//    Private attributes   //
//                         //
/////////////////////////////

// List of all modules coming from third party libraries.
const _EXTERNAL_MODULES = [
    'oc.lazyLoad',
    'ui.router',
    'ngResource',
    'ngSanitize',
    'ngTimezone',
    'ngCookies',
    'ngStorage',
    'angulartics',
    'angulartics.google.analytics',
    'angular-bind-html-compile',
    'http-auth-interceptor',
    'pascalprecht.translate',
    'toolkit.base_service',
    'dndLists',
    'as.sortable',
    'lumx',
    'cfp.hotkeys',
    'dbaq.emoji',
    'ngPromiseExtras',
];

/////////////////////////////
//                         //
//    Public attributes    //
//                         //
/////////////////////////////

// Contains the list of all angularjs modules needed by the application.
const DEPENDENCIES = [
    ..._EXTERNAL_MODULES,
    'AppTemplates',
    'Factories',
    'Services',
    'CustomServices',
    'Controllers',
    'Directives',
    'Filters',
    'Constants',
    'Values',
    'Widgets',
    'LumSite',
];

/////////////////////////////

export { DEPENDENCIES };
