/**
 * Base service v0.20.0.
 */

/* global angular */
'use strict'; // jshint ignore:line


/**
 * Generate an UUID.
 *
 * @return {string} The generated UUID.
 */
function generateUUID() {
    var time = new Date().getTime();

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function replace(char) {
        var random = (time + (Math.random() * 16)) % 16 | 0;
        time = Math.floor(time / 16);

        return ((char === 'x') ? random : ((random & 0x3) | 0x8)).toString(16);
    });
}

/**
 * Copy an object to existing one, without changing the references of both the source and the destination.
 *
 * @param {Object}  source          The object to copy from.
 * @param {Object}  destination     The destination object.
 * @param {boolean} setDefaultValue Ensure that all previous destination keys are undefined.
 */
function copyObjectToExistingOne(source, destination, setDefaultValue) {
    $.each(destination, function forEachDestinationKeys(key) {
        if (angular.isUndefined(setDefaultValue)) {
            destination[key] = undefined;
        }
    });

    $.each(source, function forEachSourceKeys(key) {
        if (jQuery.type(source[key]) === 'object') {
            if (jQuery.type(destination[key]) !== 'object') {
                destination[key] = {};
            }

            copyObjectToExistingOne(source[key], destination[key]);
        } else if (jQuery.type(source[key]) === 'array') {
            destination[key] = $.extend(true, [], source[key]);
        } else {
            destination[key] = source[key];
        }
    });
}


angular.module('toolkit.base_service',  [])
    .service('BaseService', function ()
{
    /**
     * Usage
     *
     * inject BaseService in your service
     * create a new, fresh service bind to your Api whit some options
     *     var options = {
     *          maxResults: 33,
                ...
     *          };
     *     var myService = BaseService.createService(MyApi, options);
     *
     * then customize it
     *     myService.maxResults = 42
     *     myService.defaultParams = { parentKey: 123456789 };
     *
     * and expose to the world (!)
     *
     *     return myService;
     *
     *
     *
     * You can overwrite service methods like this:
     *     myService.methodName = function(@params1...n)
     *     {
     *          // some custom logic here
     *          // ...
     *
     *          // just call the underscore version of the method at the end
     *          return this._methodName(@params1...n)
     *     }
     *
     *
     */


    /**
     * Service Builder
     * create a service instance
     * @param {Object} Api Real Api resource
     * @param {Object} options an object of options
     * @return {Object} A servive instance
     */
    function createService(Api, options)
    {
        var _serv = new SimpleService(Api, options);
        return _serv;
    }

    /**
     * List Key Service Builder
     * create a service instance, this service can handle list key
     * @param {Object} Api Real Api resource
     * @param {Object} options an object of options
     * @return {Object} A servive instance
     */

    function createListKeyService(Api, options)
    {
        var _serv = new ListKeyService(Api, options);
        return _serv;
    }

    /**
     * Paginated Service Builder
     * create a service instance with paginated fetch
     * @param {Object} Api Real Api resource
     * @param {Object} options an object of options
     * @return {Object} A servive instance
     */

    function createPaginatedService(Api, options)
    {
        var _serv = new PaginatedService(Api, options);
        return _serv;
    }


    // =============================================================================
    // ============================= Simple  =======================================
    // =============================================================================


    // PRIVATE ATTRIBUTES

    var _localListTimeout = 60000;

    /**
     * CONSTRUCTOR
     * @param {Object} Api Real Api resource
     */
    var SimpleService = function(Api, options)
    {
        var acceptedOptions = {
            maxResults: 'number',
            objectIdentifier: 'string',
            defaultParams: 'object',
            autoInit: 'boolean',
            preSave: 'function',
            postSave: 'function',
            postGet: 'function',
            postList: 'function',
            replace: 'boolean',
            prependOnList: 'boolean',
            prependOnSave: 'boolean',
            fullListResponse: 'boolean',
        };

        this.Api = Api || {};

        // max results per fetch
        this.maxResults = 30;
        // for list, which object.property should be check as object id
        this.objectIdentifier = 'objectKey';
        // store default param to pass on _getWithParams or filter
        this.defaultParams = {};
        // auto fetch some result on the first getList() call
        this.autoInit = true;
        // when fetching, replacing the list instead of updating it. Better performance by replaceing, but if you
        // need to keep the references, this option must be set to false. Not replacing also keeps the previous order.
        this.replace = true;
        // Prepend new elements instead of pushing them.
        this.prependOnList = false;
        // Prepend new element instead of pushing it.
        this.prependOnSave = false;
        // Optionally return the entire reponse object from the call to Api.fetch in callbacks rather than just the
        // list of items.
        this.fullListResponse = false;

        //
        // hooks
        //
        // preSave : transform object just before it is send to Api.save()
        this.preSave = undefined;
        // postSave : transform object just after it is send to Api.save()
        this.postSave = undefined;
        // postGet : transform object return by get() from local list or Api.get() call
        this.postGet = undefined;
        // postList : transform list return by Api.list()
        this.postList = undefined;

        // update options
        var instance = this;

        if (angular.isDefined(options))
        {
            angular.forEach(acceptedOptions, function(type, option)
            {
                if(angular.isDefined(options[option]))
                {
                    if (typeof options[option] != type)
                    {
                        throw new TypeError(option + ' must be a ' + type);
                    }

                    instance[option] = options[option];
                }
            });
        }

        // many attribut are not mandatory and can be defined at run level
        // they are set here for clarity
        //
        // Current edited object
        this._current = undefined;

        // store temp value during call proccess
        // to check response consistency
        this._lastListCallId = undefined;
        this._lastCallId = {};
        this._lastRefreshDate = undefined;
        this._saveInProgress = {};
        this._deleteInProgress = {};

        // object list, populated by fetch
        this._list = [];
    };

    /**
     * Get object by key from the _list if available, else call the Api to retrieve object.
     *
     * @param  {Object|String} key               The key used to retrieve object params.
     *                                           used to retrieve object ( use Api call).
     * @param  {Function}      callback          Success callback function.
     * @param  {Function}      errorCb           Error callback function.
     * @param  {Object}        projection        The projection we want for the returned object.
     * @param  {boolean}       [setCurrent=true] Whether or not we want to set the catched content as current.
     * @return {Object}        The object or a ref to the object in case of a Api call.
     */
    SimpleService.prototype.get = function(key, callback, errorCb, projection, setCurrent)
    {
        return this._get(key, callback, errorCb, projection, setCurrent);
    };

    /**
     * Get object by key in async way and return the promise given.
     *
     * @param  {Object|String} key               The key used to retrieve object params.
     *                                           used to retrieve object ( use Api call).
     * @param  {Function}      callback          Success callback function.
     * @param  {Function}      errorCb           Error callback function.
     * @param  {Object}        projection        The projection we want for the returned object.
     * @param  {boolean}       [setCurrent=true] Whether or not we want to set the catched content as current.
     * @return {Promise}       The promise of the object in case of an async Api call.
     */
    SimpleService.prototype.getAsync = function(key, callback, errorCb, projection, setCurrent)
    {
        return this._getAsync(key, callback, errorCb, projection, setCurrent);
    };

    /**
     * Main getList function
     * You can overwrite it like this:
     * myService.getList = function(callback, errorCb)
     * {
     *      // some custom logic here
     *      // ...
     *      // IMPORTANT
     *      return this._getList(callback, errorCb)
     * }
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     * @return {Object}            the object list
     */
    SimpleService.prototype.getList = function(callback, errorCb, projection)
    {
        return this._getList(callback, errorCb, projection);
    };

    /**
     * Main fetch function
     * You can overwrite it like this:
     * myService.fetch = function(queryFilters, callback, errorCb)
     * {
     *      // some custom logic here
     *      // manage queryFilters
     *      // extend callback
     *      // ...
     *      // IMPORTANT
     *      return this._fetch(queryFilters, callback, errorCb)
     * }
     * @param  {Object} queryFilters to pass to the Api
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     */
    SimpleService.prototype.fetch = function(queryFilters, callback, errorCb, projection, endpointName)
    {
        return this._fetch(queryFilters, callback, errorCb, projection, endpointName);
    };


    /**
     * Main delete function
     * You can overwrite it like this:
     * myService.del = function(key, callback, errorCb)
     * {
     *      // some custom logic here
     *      // IMPORTANT
     *      return this._del(key, callback, errorCb)
     * }
     * @param  {String} key key of the object to delete
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     */
    SimpleService.prototype.del = function(key, callback, errorCb)
    {
        return this._del(key, callback, errorCb);
    };

    /**
     * Main deleteMulti function
     * @param  {Array} list of object keys to delete
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     */
    SimpleService.prototype.delMulti = function(keyList, callback, errorCb)
    {
        return this._delMulti(keyList, callback, errorCb);
    };

    /**
     * Main save function
     * You can overwrite it like this:
     * myService.save = function(object, callback, errorCb)
     * {
     *      // some custom logic here
     *      // IMPORTANT
     *      return this._save(key, callback, errorCb)
     * }
     * @param  {Object} object the object to save
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     */
    SimpleService.prototype.save = function(object, callback, errorCb)
    {
        return this._save(object, callback, errorCb);
    };

    /**
     * Main isCallInProgress function
     *
     * @param {String} functionName specify witch call you want to check
     *                                     in [list, save, get, del]
     */
    SimpleService.prototype.isCallInProgress = function(functionName)
    {
        return this._isCallInProgress(functionName);
    };

    /**
     * Get the object regarding an ID. Useful for lx-select async init.
     *
     * @param  {String}   identifier The identifier to search for.
     * @param  {Function} cb   Success callback.
     */
    SimpleService.prototype.idToModel = function(identifier, cb, projection)
    {
        if (angular.isUndefined(identifier) || angular.isUndefined(this.getList()) || this.getList().length === 0)
        {
            cb();
            return;
        }

        var model = this.get(identifier, cb, projection);
        cb(model);
        return model;
    };

    /**
     * Return the identifier for the object in parameters.
     *
     * @param  {Object}   model The model to get the identifier from.
     * @param  {Function} cb    Success callback.
     */
    SimpleService.prototype.modelToId = function(model, cb)
    {
        cb(model[this.objectIdentifier]);
        return model[this.objectIdentifier];
    };

    // "PRIVATE" functions
    //
    // we need to keep them in public Api to allow overwriting

    /**
     * Get object by key in async way and return the promise given.
     *
     * @param  {Object|String} key               The key used to retrieve object params.
     *                                           used to retrieve object ( use Api call).
     * @param  {Function}      callback          Success callback function.
     * @param  {Function}      errorCb           Error callback function.
     * @param  {Object}        projection        The projection we want for the returned object.
     * @param  {boolean}       [setCurrent=true] Whether or not we want to set the catched content as current.
     * @return {Promise}       The promise of the object in case of an async Api call.
     */
    SimpleService.prototype._getAsync = function(key, callback, errorCb, projection, setCurrent)
    {
        setCurrent = (setCurrent === undefined) ? true : setCurrent;

        if(angular.isUndefined(key))
        {
            return;
        }

        // Quick and dirty way to extend get with additional behavior
        if (angular.isObject(key))
        {
            // key is an object with additional params
            var params = key;
            return this._getWithParams(params, callback, errorCb, projection, setCurrent);
        }

        // use the Api
        var instance = this;
        var param = {};
        param[this.objectIdentifier] = key;

        this._computeProjection(param, projection);

        return this.Api.get(param, function(resp)
        {
            if (instance.postGet)
            {
                resp = instance.postGet(resp);
            }
            if (setCurrent) {
                instance.setCurrent(resp);
            }
            if (angular.isFunction(callback))
            {
                callback(resp);
            }
        }, errorCb);
    };

    /**
     * Get object by key from the _list if available, else call the Api to retrieve object.
     *
     * @param  {Object|String} key               The key used to retrieve object params.
     *                                           used to retrieve object (use Api call).
     * @param  {Function}      callback          Success callback function.
     * @param  {Function}      errorCb           Error callback function.
     * @param  {Object}        projection        The projection we want for the returned object.
     * @param  {boolean}       [setCurrent=true] Whether or not we want to set the catched content as current.
     * @return {Object}        The object or a ref to the object in case of a Api call.
     */
    SimpleService.prototype._get = function(key, callback, errorCb, projection, setCurrent)
    {
        setCurrent = (setCurrent === undefined) ? true : setCurrent;

        if(angular.isUndefined(key))
        {
            return;
        }

        // Quick and dirty way to extend get with additional behavior
        if (angular.isObject(key))
        {
            // key is an object with additional params
            var allParams = this._prepareParams(key);
            return this._getWithParams(allParams, callback, errorCb, projection, setCurrent);
        }

        // return object from the list if available
        for (var idx in this._list)
        {
            if (this._list[idx][this.objectIdentifier] === key)
            {
                var resp = this._list[idx];
                if (this.postGet)
                {
                    resp = this.postGet(resp);
                }

                if (setCurrent)
                {
                    this.setCurrent(resp);
                }

                if (angular.isFunction(callback))
                {
                    callback(resp);
                }

                return resp;
            }
        }
        // object not found in local list, try to get it from serveur
        if (this._lastCallId[key] === undefined)
        {
            // use the Api
            var instance = this;
            var param = {};
            param[this.objectIdentifier] = key;

            this._computeProjection(param, projection);

            this._lastCallId[key] = generateUUID();
            return this.Api.get(param, function(resp)
            {
                if (instance.postGet)
                {
                    resp = instance.postGet(resp);
                }

                if (setCurrent) {
                    instance.setCurrent(resp);
                }

                if (angular.isFunction(callback))
                {
                    callback(resp);
                }

                delete instance._lastCallId[key];
            }, function(errorResp)
            {
                if (angular.isFunction(errorCb))
                {
                    errorCb(errorResp);
                }
                delete instance._lastCallId[key];
            }
            );

        }
        return undefined;
    };

    /**
     * Set object as current for this service
     *
     * @param {Object} object
     */
    SimpleService.prototype.setCurrent = function(object)
    {
        this._current = object;
    };

    /**
     * Get current object
     *
     * @return {Object} object
     */
    SimpleService.prototype.getCurrent = function()
    {
        return this._current;
    };

    /**
     * Get object by key in async way and return the promise given.
     *
     * @param  {Object}        params            The params used to retrieve object (use Api call).
     * @param  {Function}      callback          Success callback function.
     * @param  {Function}      errorCb           Error callback function.
     * @param  {Object}        projection        The projection we want for the returned object.
     * @param  {boolean}       [setCurrent=true] Whether or not we want to set the catched content as current.
     * @return {Object}        The retrieved content.
     */
    SimpleService.prototype._getWithParams = function(params, callback, errorCb, projection, setCurrent)
    {
        // force a Api call
        // TODO check in local list if available by params
       // use the Api
        var instance = this;
        var objectKey = params[instance.objectIdentifier] || 'new';

        params = params || {};
        setCurrent = (setCurrent === undefined) ? true : setCurrent;

        this._computeProjection(params, projection);

        this._lastCallId[objectKey] = generateUUID();
        return this.Api.get(params, function(resp)
        {
            if (instance.postGet)
            {
                resp = instance.postGet(resp);
            }
            if (setCurrent) {
                instance._current = resp;
            }
            if (angular.isFunction(callback))
            {
                callback(resp);
            }
            delete instance._lastCallId[objectKey];
        }, function(errorResp)
        {
            if (angular.isFunction(errorCb))
            {
                errorCb(errorResp);
            }
            delete instance._lastCallId[objectKey];
        });
    };

    /**
     * Get list of object
     * trigger refresh if no previous fetch or after _localListTimeout
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     * @return {Object}            the object list
     */
    SimpleService.prototype._getList = function(callback, errorCb, projection)
    {
        if (this.autoInit &&
            angular.isUndefined(this._lastListCallId) &&
            (angular.isUndefined(this._lastRefreshDate) ||
            Date.now() - this._lastRefreshDate > _localListTimeout))
        {
            if (angular.isFunction(callback))
            {
                var instanceList = this;

                this.fetch(undefined, function(resp)
                {
                    callback(resp.items, instanceList.fullListResponse ? resp : undefined);
                }, errorCb, projection);
            }
            else
            {
                this.fetch(undefined, callback, errorCb, projection);
            }
        }

        if (angular.isFunction(callback))
        {
            callback(this._list);
        }
        return this._list;
    };

    /**
     * Fetch object list from Api
     * Should not be used directly, use 'fetch' instead
     * @param  {Object} queryFilters to pass to the Api
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     */
    SimpleService.prototype._fetch = function(queryFilters, callback, errorCb, projection, endpointName)
    {
        endpointName = endpointName || 'list';
        if (!angular.isFunction(this.Api[endpointName])) {
            errorCb();

            return;
        }

        var requestData = {
            maxResults: this.maxResults
        };

        // merge queryFilters in requestData
        for (var o in this.defaultParams)
        {
            requestData[o] = this.defaultParams[o];
        }

        // merge queryFilters in requestData
        for (var oFilters in queryFilters)
        {
            requestData[oFilters] = queryFilters[oFilters];
        }

        this._computeProjection(requestData, projection);

        var instanceList = this;

        return this.Api[endpointName](requestData, function(resp)
        {
            // endpoint do not return 'items' attribut if no result
            if (angular.isDefined(resp.items))
            {
                if (instanceList.postList)
                {
                    resp.items = instanceList.postList(resp.items);
                }

                if (instanceList.replace)
                {
                    instanceList._list = resp.items;
                }
                else
                {
                    for (var oldListIdx = instanceList._list.length - 1; oldListIdx >= 0; oldListIdx--)
                    {
                        var oldItem = instanceList._list[oldListIdx];

                        if (oldItem)
                        {
                            for (var newListIdx = 0; newListIdx < resp.items.length; newListIdx++)
                            {
                                var newItem = resp.items[newListIdx];
                                if (!newItem)
                                {
                                    continue;
                                }

                                if (newItem[instanceList.objectIdentifier] === oldItem[instanceList.objectIdentifier])
                                {
                                    angular.copy(newItem, oldItem);
                                    resp.items.splice(newListIdx, 1);
                                    break;
                                }
                            }
                        }
                    }

                    for (var pushIdx = 0; pushIdx < resp.items.length; pushIdx++)
                    {
                        instanceList._list.push(resp.items[pushIdx]);
                    }
                }
            }
            else
            {
                instanceList._list.splice(0, instanceList._list.length);
            }

            instanceList._lastListCallId = undefined;
            instanceList._lastRefreshDate = Date.now();

            if (angular.isFunction(callback))
            {
                callback(instanceList._list, instanceList.fullListResponse ? resp : undefined);
            }
        }, function(err)
        {
            instanceList._lastListCallId = undefined;
            if (angular.isFunction(errorCb))
            {
                errorCb(err);
            }
        });
    };

    /**
     * Delete object and update _list
     * @param  {String} key key of the object to delete
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     */
    SimpleService.prototype._del = function(key, callback, errorCb)
    {
        var instance = this;
        instance._deleteInProgress[key] = generateUUID();

        var param = {};
        param[this.objectIdentifier] = key;

        this.Api.delete(param, function()
        {
            for(var idx in instance._list)
            {
                if(instance._list[idx][instance.objectIdentifier] === key)
                {
                    instance._list.splice(idx, 1);
                    break;
                }
            }
            delete instance._deleteInProgress[key];
            if (angular.isFunction(callback))
            {
                callback();
            }
        }, function(err)
        {
            delete instance._deleteInProgress[key];
            if (angular.isFunction(errorCb))
            {
                errorCb(err);
            }
        });
    };

    /**
     * Delete objects and update _list
     * @param  {Array} list of object keys to delete
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     */
    SimpleService.prototype._delMulti = function(keyList, callback, errorCb)
    {
        var instance = this;
        for(var idx in keyList)
        {
            instance._deleteInProgress[keyList[idx]] = generateUUID();
        }

        var param = {};
        param.uid = keyList;

        this.Api.deleteMulti(param, function(resp)
        {
            for(var item in resp.uid)
            {
                for(var idx in instance._list)
                {
                    if(instance._list[idx][instance.objectIdentifier] === resp.uid[item])
                    {
                        instance._list.splice(idx, 1);
                    }
                }
            }
            if (angular.isFunction(callback))
            {
                callback(resp);
            }

            for(var jdx in keyList)
            {
                delete instance._deleteInProgress[keyList[jdx]];
            }

        }, function(err)
        {
            if (angular.isFunction(errorCb))
            {
                errorCb(err);
            }

            for(var idx in keyList)
            {
                delete instance._deleteInProgress[keyList[idx]];
            }
        });
    };

    /**
     * Save object and update _list
     * @param  {Object}  object the object to save
     * @param  {Function} callback success callback
     * @param  {Function} errorCb  error callback
     */
    SimpleService.prototype._save = function(object, callback, errorCb)
    {

        var instance = this;
        var objectKey = object[instance.objectIdentifier] || 'new';
        instance._saveInProgress[objectKey] = generateUUID();

        // use preSave hook
        if (angular.isDefined(this.preSave))
        {
            object = this.preSave(object);
        }

        this.Api.save(object, function(resp)
        {
            var insert = false;

            // use postSave hook
            if (angular.isDefined(instance.postSave))
            {
                resp = instance.postSave(resp);
            }

            for(var idx in instance._list)
            {
                if(instance._list[idx][instance.objectIdentifier] === resp[instance.objectIdentifier])
                {
                    copyObjectToExistingOne(resp, instance._list[idx]);
                    insert = true;
                }
            }

            if(!insert)
            {
                if (instance.prependOnSave)
                {
                    instance._list.unshift(resp);
                }
                else{
                    instance._list.push(resp);
                }
            }
            instance.setCurrent(resp);
            delete instance._saveInProgress[objectKey];
            if (angular.isFunction(callback))
            {
                callback(resp);
            }

        }, function(err)
        {
            delete instance._saveInProgress[objectKey];
            if (angular.isFunction(errorCb))
            {
                errorCb(err);
            }
        });
    };

    /**
     * isCallInProgress check Service activity
     * usefull for notificatio nand/or progress bar
     * @return {Boolean} true if any call is in progress
     */
    SimpleService.prototype._isCallInProgress = function(functionName, idForUniqueCall)
    {
        var callIds = {
            list: '_lastListCallId',
            get: '_lastCallId',
            save: '_saveInProgress',
            del: '_deleteInProgress'
        };

        if (callIds[functionName] !== undefined)
        {
            if (functionName === 'list')
            {
                return this[callIds[functionName]] !== undefined;
            }
            else
            {
                if (idForUniqueCall)
                {
                    return angular.isDefined(this[callIds[functionName]]) && idForUniqueCall in this[callIds[functionName]];
                }
                else
                {
                    return angular.isDefined(this[callIds[functionName]]) && Object.keys(this[callIds[functionName]]).length !== 0;
                }
            }
        }

        var isCallInProgress = false;
        var instance = this;
        angular.forEach(callIds, function(id, functionName)
        {
            if (functionName === 'list')
            {
                isCallInProgress = isCallInProgress || instance[id] !== undefined;
            }
            else
            {
                if (idForUniqueCall)
                {
                    isCallInProgress = isCallInProgress || (angular.isDefined(instance[id]) && idForUniqueCall in instance[id]);
                }
                else
                {
                    isCallInProgress = isCallInProgress || (angular.isDefined(instance[id]) && Object.keys(instance[id]).length !== 0);
                }
            }
        });
        return isCallInProgress;
    };

    /**
     * Compute projection parameters (fields) for a query.
     *
     * @param  {Object}              params     The parameters in which add the projection.
     * @param  {string|Array|Object} projection The projection that must be used.
     *                                          This projection can be:
     *                                              - a comma separated list of fields string:
     *                                                  "field1,field2/subField1,field3(subField2,subField3)";
     *                                              - an array of fields: [
     *                                                  "field1",
     *                                                  "field2/subField1",
     *                                                  "field3(subField2,subField3)",
     *                                              ];
     *                                              - an object describing the wanted structure ({
     *                                                  field1: true,
     *                                                  field2: [
     *                                                      subField1,
     *                                                  ],
     *                                                  field3: {
     *                                                      subField2: true,
     *                                                      subField3: true,
     *                                                  },
     *                                              });
     * @return {Object}              The parameters for the query with the projection.
     */
    SimpleService.prototype._computeProjectionRecursively = function (params, projection, parent)
    {
        var instance = this;

        params = (angular.isUndefined(params)) ? {} : params;

        if (angular.isUndefined(params.fields) && angular.isDefined(projection) && ((((angular.isString(projection) || angular.isArray(projection)) && projection.length > 0) || (angular.isObject(projection) && Object.keys(projection).length > 0)) || true))
        {
            params.fields = '';

            if (angular.isString(projection)) {
                params.fields = (angular.isDefined(parent) && parent.length > 0) ? parent + '/' + projection : projection;
            } else if (angular.isObject(projection) || angular.isArray(projection)) {
                var index = 0;
                var firstWritten = true;

                if (angular.isDefined(parent) && parent.length > 0 && !angular.isArray(projection)) {
                    params.fields += parent + '(';
                }
                angular.forEach(projection, function forEachProjectionFields(value, field) {
                    if (angular.isUndefined(value) || value === false) {
                        return;
                    }

                    params.fields += (!firstWritten) ? ',' : '';
                    if (angular.isArray(projection)) {
                        params.fields += (angular.isDefined(parent) && parent.length > 0) ? parent + '/' + value : value;

                        firstWritten = false;
                    } else {
                        var recursiveParams = {};
                        instance._computeProjectionRecursively(recursiveParams, value, field);

                        if (angular.isDefined(recursiveParams.fields) && recursiveParams.fields.length > 0) {
                            params.fields += recursiveParams.fields;

                            firstWritten = false;
                        }
                    }

                    index++;
                })

                if (angular.isDefined(parent) && parent.length > 0 && !angular.isArray(projection)) {
                    params.fields += ')';
                }
            } else if (angular.isDefined(projection) && angular.isDefined(parent) && parent.length > 0) {
                params.fields = parent;
            }
        }

        return params;
    };

    /**
     * Compute the projection.
     * If necessary, add `more`, `callId` and `cursor` to the projection if they are not already projected.
     *
     * @param  {Object}              params     The parameters in which add the projection.
     * @param  {string|Array|Object} projection The projection (see _computeProjectionRecursively for more information).
     * @return {Object}              The parameters for the query with the projection.
     */
    SimpleService.prototype._computeProjection = function(params, projection) {
        this._computeProjectionRecursively(params, projection);

        if (angular.isDefined(params.fields) && params.fields.length > 0) {
            var firstLevelFields = params.fields.replace(/\([^)]+\)/, '');
            if (firstLevelFields.indexOf('more') === -1) {
                params.fields += ',more';
            }
            if (firstLevelFields.indexOf('callId') === -1) {
                params.fields += ',callId';
            }
            if (firstLevelFields.indexOf('cursor') === -1) {
                params.fields += ',cursor';
            }
        }

        return params;
    };

    /**
     * Prepare the parameters for a query.
     * Add the default parameters to the query parameters and compute the projection.
     *
     * @param  {Object}              params     The parameters of the query
     * @param  {string|Array|Object} projection The projection to use in the query
     * @return {Object}              The full parameters object.
     */
    SimpleService.prototype._prepareParams = function(params, projection) {
        params = params || {};
        var allParams = angular.copy(this.defaultParams);
        angular.extend(allParams, params);

        if (angular.isDefined(projection)) {
            this._computeProjection(allParams, projection);
        }

        return allParams;
    }

    // =============================================================================
    // ============================ PAGINATED  =====================================
    // =============================================================================

    /*
     * Paginated Service
     * manage paginated list of object
     *
     * 'public' Api:
     *
     *  filterize()            initialize query
     *  getList()              get current list
     *  nextPage()             fetch next page of results
     *  get()
     *  save()
     *  del()
     *  isCallinProgress()
     *
     *  maxResults
     *  defaultParams
     * this service inherit from SimpleService
     *
     */
    var PaginatedService = function(Api, options)
    {
        // inherit property from SimpleService
        SimpleService.call(this, Api, options);

        this._cursor = undefined;
        this._more = false;
        this._firstRun = true;
        this._params = {};
    };

    PaginatedService.prototype = Object.create(SimpleService.prototype);

    /**
     * Reset the local list, the local filter and query for the first results
     * @param {Object} params
     * @param {Function} callback
     * @param {Function} errorCb
     */
    PaginatedService.prototype.filterize = function(params, callback, errorCb, projection, endpointName)
    {
        return this._filterize(params, callback, errorCb, projection, endpointName);
    };

    PaginatedService.prototype._filterize = function(params, callback, errorCb, projection, endpointName)
    {
        var allParams = this._prepareParams(params, projection);

        this._list = [];

        this._lastListCallId = undefined;
        this._more = true;
        this._cursor = undefined;
        this._params = allParams;

        return this.fetch(allParams, callback, errorCb, endpointName);
    };

    /**
     * Return "_more" status
     */
    PaginatedService.prototype.hasMore = function()
    {
        return this._more;
    };

    /**
     * Get the next page from the API according to the local filter attributes
     * Do nothing if the "_more" boolean is false
     * @param {Function} callback
     * @param {Function} errorCb
     */
    PaginatedService.prototype.nextPage = function(callback, errorCb, endpointName)
    {
        return this._nextPage(callback, errorCb, endpointName);
    };

    PaginatedService.prototype._nextPage = function(callback, errorCb, endpointName)
    {
        if (this._more && !this._isCallInProgress())
        {
            this.fetch(this._params, callback, errorCb, endpointName);
        }
    };


    /**
     * Return current list and initialize if empty
     *
     * @param  {Function}   callback
     * @param  {Function}   errorCb
     * @return {List}       current list
     */
    PaginatedService.prototype._getList = function(callback, errorCb, projection)
    {
        // initialize this._list
        if (this._firstRun && this._list.length === 0 && this.autoInit)
        {
            this.filterize(null, callback, errorCb, projection);
            this._firstRun = false;
        }

        if (angular.isFunction(callback))
        {
            callback(this._list);
        }
        return this._list;
    };

    /**
     * Call the API to list items according to the local filter
     * Use a callId in case of multiple request during a short time
     * Handle with pagination params
     * Update the local list
     *
     * @param {Object} query filters
     * @param {Function} callback
     * @param {Function} errorCb
     */
    PaginatedService.prototype._fetch = function(queryFilter, callback, errorCb, endpointName)
    {
        endpointName = endpointName || 'list';
        if (!angular.isFunction(this.Api[endpointName])) {
            errorCb();

            return;
        }

        this._lastListCallId = generateUUID();

        if (!this._more)
        {
            return;
        }

        var requestData = {
            maxResults: this.maxResults,
            more: this._more,
            cursor: this._cursor
        };

        this._firstRun = false;
        queryFilter = queryFilter || {};
        angular.extend(requestData, queryFilter);

        var instanceList = this;

        return this.Api[endpointName](requestData, function(resp)
        {
            // endpoint do not return 'items' attribut if nDesult
            if (angular.isDefined(resp.items))
            {
                if (instanceList.prependOnList && angular.isArray(resp.items))
                {
                    // Reverse list if prepend, before post hook call.
                    resp.items.reverse();
                }

                if (instanceList.postList)
                {
                    resp.items = instanceList.postList(resp.items, instanceList._list);
                }
            }
            instanceList._more = resp.more;
            instanceList._cursor = resp.cursor;
            instanceList._lastListCallId = undefined;
            instanceList._lastRefreshDate = Date.now();

            if (resp.items)
            {
                if (instanceList.prependOnList && angular.isArray(resp.items))
                {
                    instanceList._list = resp.items.concat(instanceList._list);
                }
                else
                {
                    instanceList._list = instanceList._list.concat(resp.items);
                }
            }

            instanceList.deduplicate();

            if (angular.isFunction(callback))
            {
                callback(instanceList._list, instanceList.fullListResponse ? resp : undefined);
            }
        }, function(err)
        {
            instanceList._more = false;

            instanceList._lastListCallId = undefined;
            if (angular.isFunction(errorCb))
            {
                errorCb(err);
            }
        });
    };

    /**
     * Remove duplicates from items list.
     */
    PaginatedService.prototype.deduplicate = function() {
        var instance = this;

        var inList = {};
        var deduplicatedList = [];
        angular.forEach(instance._list, function forEachListItems(item) {
            var objectKey = item[instance.objectIdentifier] || generateUUID();
            if (!inList[objectKey]) {
                inList[objectKey] = true;
                deduplicatedList.push(item);
            }
        });

        instance._list = deduplicatedList;
    };


    // =============================================================================
    // ============================== LIST KEY =====================================
    // =============================================================================

    /*
     * List Key Service
     * This service manage a list of PaginatedService by name
     * and delegate calls to them
     *
     * 'public' Api
     *  filterize(...., listKey)    initialize query
     *  getList(...., listKey)      get current list
     *  nextPage(...., listKey)     fetch next page of results
     *  get(...., listKey)
     *  save(...., listKey)
     *  del(...., listKey)
     *  isCallinProgress(listKey)
     *
     *  maxResults
     *  defaultParams
     *
     */

    var listKeyBaseService = createPaginatedService;

    var ListKeyService = function(Api, options)
    {
        var acceptedOptions = [
            'maxResults',
            'objectIdentifier',
            'defaultParams',
            'autoInit'];

        this.Api = Api;
        this.maxResults = 30;
        // auto fetch some result on the first getList() call
        this.autoInit = true;
        // default param to pass to filterize
        this.defaultParams = {};
        this.objectIdentifier = 'objectKey';
        // service instances by key
        this._services = {};

        this.options = options || {};

        var instance = this;
        if (angular.isDefined(options))
        {
            angular.forEach(acceptedOptions, function(option)
            {
                if(angular.isDefined(options[option]))
                {
                    instance[option] = options[option];
                }
            });
        }
    };

    /**
     * Initialize a listKey service
     * @param  {String}  listKey         name of te listKey
     * @param  {Object}  defaultParams   defaults parameters to use, else use ListKeyService.defaultParams
     * @param  {Array}   [items=[]]      the default item to initialize the list with.
     * @param  {boolean} [hasMore=false] indicates if there are more items after the one given.
     * @param  {string}  [cursor]        the cursor to get the next items.
     */
    ListKeyService.prototype.initList = function(listKey, defaultParams, items, hasMore, cursor)
    {
        var _service = listKeyBaseService(this.Api, this.options);

        _service._list = items || [];
        _service._more = hasMore || false;
        _service._cursor = cursor;

        _service.maxResults = this.maxResults;
        _service.objectIdentifier = this.objectIdentifier;
        _service.defaultParams = defaultParams || this.defaultParams;

        this._services[listKey] = _service;
    };

    /**
     * Intialize request
     * @param {String} listKey which list to use
     */
    ListKeyService.prototype.filterize = function(params, callback, errorCb, listKey, projection, endpointName)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._filterize(params, callback, errorCb, projection, endpointName);
        }
    };

    /**
     * Get next page of result if any
     * @param {String} listKey which list to use
     */
    ListKeyService.prototype.nextPage = function(callback, errorCb, listKey, endpointName)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._nextPage(callback, errorCb, endpointName);
        }
    };

    /**
     * Get current list
     * @param {String} listKey which list to use
     */
    ListKeyService.prototype.getList = function(callback, errorCb, listKey, projection)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._getList(callback, errorCb, projection);
        }
    };


    ListKeyService.prototype.get = function(key, callback, errorCb, listKey, projection, setCurrent)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._get(key, callback, errorCb, projection, setCurrent);
        }
    };

    ListKeyService.prototype.getAsync = function(key, callback, errorCb, listKey, projection, setCurrent)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._getAsync(key, callback, errorCb, projection, setCurrent);
        }
    };

    ListKeyService.prototype.save = function(object, callback, errorCb, listKey)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._save(object, callback, errorCb);
        }
    };

    ListKeyService.prototype.del = function(key, callback, errorCb, listKey)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._del(key, callback, errorCb);
        }
    };

    ListKeyService.prototype.delMulti = function(keyList, callback, errorCb, listKey)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._delMulti(keyList, callback, errorCb);
        }
    };

    ListKeyService.prototype.getCurrent = function(listKey)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey]._current;
        }
    };

    ListKeyService.prototype.setCurrent = function(object, listKey)
    {
        listKey = this._getListKey(listKey);

        if (angular.isDefined(this._services[listKey])) {
            return this._services[listKey].setCurrent(object);
        }
    };

    /**
     * Get the correct key for the list and init service instance
     * @param {string} listKey
     * @return {string}
     */
    ListKeyService.prototype._getListKey = function(listKey)
    {
        var key = (angular.isDefined(listKey)) ? listKey : 'default';

        // get from listKey or create a new list
        if (angular.isUndefined(this._services) || angular.isUndefined(this._services[key]))
        {
            this.initList(key);
        }
        return key;
    };

    // ListKeyService.prototype.fetch = function(queryFilters, callback, errorCb, listKey)
    // {
    //     listKey = this._getListKey(listKey);
    //     return this._services[listKey]._fetch(queryFilters, callback, errorCb);
    // };

    ListKeyService.prototype.isCallInProgress = function(listKey, functionName)
    {
        var inProgress = false;

        if (angular.isString(listKey) &&  // scope.$watch may send additional params, skip them
            angular.isDefined(listKey) &&
            angular.isDefined(this._services[listKey]))
        {
            inProgress = this._services[listKey]._isCallInProgress(functionName) || inProgress;
        }
        else
        {
            for (var key in this._services)
            {
                inProgress = this._services[key]._isCallInProgress(functionName) || inProgress;
            }
        }
        return inProgress;
    };

    ListKeyService.prototype.hasMore = function(listKey)
    {
        var key = (angular.isDefined(listKey)) ? listKey : 'default';

        if (angular.isDefined(this._services[key])) {
            return this._services[key].hasMore();
        }
    };

    return {
        createService: createService,
        createPaginatedService: createPaginatedService,
        createListKeyService: createListKeyService,
        // return object services to allow inheritence
        SimpleService: SimpleService,
        PaginatedService: PaginatedService,
        ListKeyService: ListKeyService
    };
});
